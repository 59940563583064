




























































































import { computed, ref, watchEffect } from '@vue/composition-api';
import useTeamDoc from '@/composables/useTeamDoc';
import store, { useUserState, useUserActions, useUserGetters } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import axios from 'axios';

export default {
  components: {
    ABtn: () => import('@/components/atoms/AButton.vue')
  },

  props: {
    value: {
      type: [Object, Array],
      default: () => {}
    },
    data: {
      type: [Object, Array],
      default: () => {}
    },
    adkType: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ''
    }
  },

  setup(props: any, ctx) {
    const submittedVideo: any = ref({});
    const successMsg = ref(false);
    const { getUser } = useUserGetters(['getUser']);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const { updateAdkData } = useUserActions(['updateAdkData']);
    const errorMsg = ref(false);
    const teamDoc = ref();
    const { getTeamDoc }: any = useTeamDoc();
    const isCreating = ref(false);
    const msg = ref('');
    const verifyLoading = ref(false);
    const apiErrors = ref<Array<string>>([]);
    const presentationLink = ref('');

    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    async function loadInitialData() {
      teamDoc.value = await getTeamDoc({
        program_id: ctx.root.$route.params.programId || programDoc.value?.data?.program_id,
        user_id: props.isMonitor ? props.studentId : user.value?._id.toString(),
        public_id: props?.value?.data?._id
      });
      const index = teamDoc.value.adks.findIndex(a => a.name === props.adkType);
      submittedVideo.value = teamDoc.value.adks[index].submittedVideo;
      presentationLink.value = `https://youtu.be/${submittedVideo.value.id}`;
    }
    if (props.adkType !== 'deliverable') loadInitialData();

    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });

    const fieldIndex = computed(() => {
      if (props.adkType === 'deliverable')
        return adkData.value?.fields.findIndex(f => f.name === 'm-deliverable-default');
      return adkData.value?.fields.findIndex(f => f.name === 'm-presentation');
    });

    const maxMinutes = computed(() => {
      return (
        adkData.value?.fields?.find(f => f.model === 'videoMaxLength')?.setup?.videoMaxLength ||
        adkData.value?.fields?.find(f => f.model === 'videoMaxLength')?.preset?.videoMaxLength
      );
    });

    const fieldValue = computed({
      get: () => adkData.value.fields[fieldIndex.value].value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    async function savePresentation() {
      isCreating.value = true;
      try {
        const data = {
          projectPresentation: submittedVideo.value
        };
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.patch(`${ENDPOINT_API}?deliverable_id=${props.data._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          successMsg.value = true;
          msg.value = res.data.message;
          isCreating.value = false;
        }
      } catch (error) {
        console.log(error);
        errorMsg.value = true;
        msg.value = 'Something went wrong';
        isCreating.value = false;
      }
    }

    async function verifyLink() {
      try {
        verifyLoading.value = true;
        const payload = {
          videoLink: presentationLink.value,
          videoMaxLength: maxMinutes.value || 60
        };

        const resp = await axios.post(
          `${process.env.VUE_APP_PROGRAM_DOC}/programs/youtube-link`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );

        if (resp.status === 200) {
          if (props.adkType === 'deliverable') {
            submittedVideo.value = resp.data.body?.submittedVideo;
            await savePresentation();
          } else {
            if (!fieldValue.value?.submittedVideo) fieldValue.value.submittedVideo = {};
            fieldValue.value.submittedVideo = resp.data.body?.submittedVideo;
            submittedVideo.value = resp.data.body?.submittedVideo;
            const data = {
              submittedVideo: submittedVideo.value,
              videoMaxLength: maxMinutes.value
            };
            const res = await updateAdkData({
              program_id: teamDoc.value._id.toString(),
              document_type: 'ProgramTeam',
              data: { ...data, name: props.adkType }
            });
            teamDoc.value = res.data;
            ctx.emit('refetchTeamDoc', res.data);
          }
          verifyLoading.value = false;
        }
      } catch (error) {
        presentationLink.value = '';
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: error?.response?.data?.error?.description,
          type: 'error',
          isShowSnackbar: true
        });
        verifyLoading.value = false;
      }
    }

    if (props.adkType === 'deliverable' && props.data?.projectPresentation) {
      submittedVideo.value = { ...props.data?.projectPresentation } || {};
      presentationLink.value = `https://youtu.be/${props.data?.projectPresentation?.id}` || '';
    }
    function openYoutubeVideo(id) {
      window.location.href = `https://youtu.be/${id}`;
    }
    return {
      successMsg,
      msg,
      errorMsg,
      submittedVideo,
      presentationLink,
      verifyLoading,
      apiErrors,
      verifyLink,
      savePresentation,
      isCreating,
      maxMinutes,
      openYoutubeVideo
    };
  }
};
